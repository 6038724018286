import Hero from "src/components/elements/Hero/Hero";
import Schedule from "src/components/elements/Schedule/Schedule";

const Home = () => {
  return (
    <>
      <Hero />
      <section className="section section--white-bg">
        <div className="container">
          <div className="schedule-wrapper">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="has-text-centered">
                  <h3 className="emphasis">
                    Summer Term<br/>
                    Monday 15th April - Thursday 23rd May<br/>
                    NO CLASSES MONDAY 6TH MAY<br/>
                    Monday 3rd June - Thursday 18th July</h3>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-6">
                <Schedule
                  day="Monday"
                  items={['7.15pm Advanced Tap', '8pm Fitsteps', '8.45pm Fitsteps Tone']}
                  animation="fade-right"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-offset-6 is-6">
                <Schedule
                  className="schedule--right"
                  day="Tuesday"
                  items={['12pm Westend Workout', '1pm Intermediate Tap', '6.45pm Elementary Tap', '7.30pm Intermediate Tap', '8.15pm Intermediate Ballet', '9.05pm Pointe']}
                  animation="fade-left"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-6">
                <Schedule
                  day="Wednesday"
                  items={['7.15pm West End Workout', '8.15pm Elementary Ballet']}
                  animation="fade-right"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-offset-6 is-6">
                <Schedule
                  className="schedule--right schedule--last"
                  day="Thursday"
                  items={['6.15pm Fitsteps', '7.15pm West End Workout']}
                  animation="fade-left"
                />
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-12">
              <h4 className="title is-4">Prices</h4>
              <p>Pay Half Termly - 45 min classes £5.75 each, 20 min classes £3.20 each</p>
              <p>PAYG - 45 min classes £7.25 each, 20 min classes £4 each - Fitsteps, Tone and WEW only</p>
            </div>
          </div>
          <div className="columns is-multiline">
            <div className="column is-12">
              <h4 className="title is-4">Finding us and getting in touch</h4>
              <p>
                1st Floor<br/>
                3 Bury Mead Road<br/>
                Hitchin<br/>
                SG5 1RT
              </p>
              <br/>
              <p>
                Email - <a href="mailto:info@jsdanceacademy.co.uk">info@jsdanceacademy.co.uk</a><br/>
                Facebook - <a href="https://www.facebook.com/JS-Dance-Academy-223523381032449" target="_blank" rel="noreferrer">JS Dance Academy</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;