const PrivacyPolicy = () => {
  return (
    <section className="section section--white-bg">
      <div className="container">
        <h1 className="title is-3 has-text-centered">Privacy Policy</h1>
        <div className="columns">
          <div className="column is-12">
            <div className="content">
              <p>
                This privacy policy explains how we use any personal information we collect about you when you use this website. JS Dance Academy is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.
              </p>
              <p>
                Pupil's registration forms will be kept at the academy in a locked box.
              </p>
              <p>
                Details from completed registration forms are transferred to a spreadsheet on a password protected computer (only used by JS Dance Academy) and will not be used for any other purpose outside of the academy, or disclosed to any outside parties (apart from invoicing).
              </p>
              <p>
                The National Association of Teachers of Dancing (NATD) receives exam candidates names and dates of birth. Exam results and NATD PIN numbers are added to the pupils details on this spreadsheet. No other information is stored.
              </p>
              <p>
                Email addresses will be used to contact pupils/parents about all academy business. Invoices are sent via WaveApps using pupils name and pupils/parents email address. Texts to mobiles may be used to cancel classes but do not use any outside parties. Medical/emergency contact details are required in case of issues with pupils whilst at the studio.
              </p>
              <p>
                Paper forms will be shredded/spreadsheet entries deleted after 3 months of non-attendance.
              </p>
              <p>
                You have the right to see any data held regarding you/your child. You also have the right to correct any wrong details and opt out of receiving emails and texts from JS Dance Academy. If you wish to opt out of receiving emails and texts please talk to the school principal.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;